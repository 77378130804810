import React, { Suspense } from 'react';
import { Head, Link } from '@inertiajs/react';
import { CiLogin } from 'react-icons/ci';
import Footer from '@/Components/Footer.jsx';
import LazyImage from '@/Components/LazyImage.jsx';
import Loading from '@/Components/Loading.jsx';

export default function Welcome({ auth }) {
  return (
    <>
      <Head title="LinkBlend" />
      <div className="flex flex-col h-screen bg-indigo-400">
        <div className="flex-grow">
          <div className="bg-zinc-50 pb-5 m-5 rounded-tl-md rounded-br-md rounded-tr-3xl rounded-bl-3xl">
            <header className="pt-5 pl-5" data-cy="heading">
              <span className="text-4xl font-bold mb-4 text-teal-600 font-serif">linkblend</span>
            </header>
            <div className="flex flex-col pt-5 pl-5 pr-5">
              <h2 className="text-lg font-bold mt-0 sm:mt-10">Welcome to LinkBlend!</h2>
              <h3 className="text-md mt-4 text-black">
                The all-in-one bio link tool that helps you share more of your content and grow your audience. <br /> With LinkBlend, you can create a
                custom page with all your important links in one place, and even track how many people click on them.
              </h3>
            </div>
            <Suspense fallback={<Loading />}>
              <LazyImage src="images/welcome-img.webp" alt="Welcome Image" />
            </Suspense>
            <div className="flex flex-col justify-end">
              <div className="text-sm leading-tight select-none mx-auto w-full">
                <div className="flex justify-center items-center w-full">
                  {auth.user ? (
                    <Link
                      href={route('dashboard')}
                      className="transform hover:-rotate-3 flex cursor-pointer transition ease-in duration-300 hover:-translate-y-1 hover:scale-110 text-2xl text-center bg-teal-500 p-16 pt-2 pb-2 lg:pt-4 lg:pb-4 rounded-full"
                    >
                      <span className="flex text-lime-50 sm:text-lg text-sm">Go to Dashboard</span>
                    </Link>
                  ) : (
                    <Link
                      href={route('login')}
                      className="transform hover:-rotate-3 flex cursor-pointer transition ease-in duration-300 hover:-translate-y-1 hover:scale-110 text-2xl text-center bg-teal-500 pt-2 pb-2 p-16 lg:pt-4 lg:pb-4 rounded-full"
                    >
                      <span className="flex text-lime-50 text-lg">
                        Log in <CiLogin className="ml-2 mt-1" />
                      </span>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
