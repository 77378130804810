import React from 'react';

const LazyImage = ({ src, alt, id }) => {
  return (
    <div className="flex sm:justify-end justify-center">
      <img
        className={'w-1/3 mt-5 mb-5 rounded-2xl sm:rounded-l-2xl sm:rounded-r-none bg-gray-100 shadow-sm'}
        style={{ aspectRatio: '3 / 2' }}
        src={src}
        alt={alt}
        id={id}
      />
    </div>
  );
};

export default LazyImage;
